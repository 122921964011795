import SearchMonth from "../Component/Search/SearchMonth";
import SearchRange from "../Component/Search/SearchRange";

import React, { useState, useEffect } from "react";
import Datagrid from "../Component/Datagrid/Datagrid_chart";
import MultilineForm from "../Component/Search/MuiltilineForm";
import TitleComponent from "../Component/Title/TitleComponent";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";
import DialogComponent from "../Component/Popup/DialogComponent";
import IndvRefund from "../Component/business/refund/IndvRefund";
import JSONasXlsx from "../Modules/JSON-to-Excel/JSONasXlsx";
import { useRecoilState } from "recoil";
import { modalAtom } from "../states/Atoms/StatusAtoms";
import { vehiclesState } from "../states/Atoms/vehiclesAtom";
import { FormControl, MenuItem, Select } from "@mui/material";
import IndivTaxDescription from "../Component/Card/IndivTaxDescription";

const ChartPage = () => {
  const [type, setType] = useState("A");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [value, setValue] = useState("1");
  const [vinNumber, setVinNumber] = useState("");
  const [pageSize, setPageSize] = useState(50);
  const [vehiclesData, setVehiclesData] = useRecoilState(vehiclesState);

  // 탭 변경 시 리스트 초기화
  useEffect(() => {
    setVehiclesData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {}, [vehiclesData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useRecoilState(modalAtom);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (vin) => {
    setOpen(true);
  };

  return (
    <>
      <TitleComponent Title="기간/일괄 조회" type={2} />
      <div className="container">
        <Box
          sx={{
            display: "flex",
            alignItems: "stretch",
            flex: 1,
            background: "#ffffff",
          }}
        >
          <Box
            sx={{
              flex: 1,
              mt: 4,
              mb: 2,
              p: 1,
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <StyledTab label="월별조회" value="1" />
                  <StyledTab label="일괄조회" value="2" />
                  <StyledTab label="기간조회" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ p: 2 }}>
                <SearchMonth
                  // SetVehicleData={SetVehiclesData}
                  SetVehicleData={setVehiclesData}
                  setYear={setYear}
                  year={year}
                  month={month}
                  setMonth={setMonth}
                  setType={setType}
                  type={type}
                />
              </TabPanel>
              <TabPanel value="2" sx={{ p: 2 }}>
                <MultilineForm SetVehicleData={setVehiclesData} />
              </TabPanel>
              <TabPanel value="3" sx={{ p: 2 }}>
                <SearchRange setType={setType} type={type} />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", pb: 10, gap: 2 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box color={"#aaa"} pl={1} fontSize={14}>
              총 {vehiclesData?.length}개의 조회 결과
            </Box>
            <Box display="flex" gap="5px">
              <IndivTaxDescription />
              <FormControl size="small">
                <Select
                  value={pageSize}
                  onChange={(e) => setPageSize(e.target.value)}
                >
                  <MenuItem value={10}>10개씩 보기</MenuItem>
                  <MenuItem value={50}>50개씩 보기</MenuItem>
                  <MenuItem value={100}>100개씩 보기</MenuItem>
                </Select>
              </FormControl>
              <JSONasXlsx vehicleData={vehiclesData} />
            </Box>
          </Box>
          <Datagrid
            pageSize={pageSize}
            vehicleData={vehiclesData}
            handleOpen={handleOpen}
            setVinNumber={setVinNumber}
          />
        </Box>
      </div>

      <DialogComponent open={open} handleClose={handleClose}>
        <IndvRefund vin={vinNumber} />
      </DialogComponent>
    </>
  );
};

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "#aaa",
    "&.Mui-selected": {
      color: "#000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);
export default ChartPage;
