import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import MUITableNoHeader from "../Table/MUITableNoHeader";
import NotAppliedType from "./NotAppliedType";
import { authoritiesAtom } from "../../states/Atoms/AuthAtoms";
import EcoFriendlyVehicleDialog from "../Popup/EcoFriendlyVehicleDialog";
import EcoFriendlyPopover from "../Popover/EcoFriendlyVehiclePopover";
import IndivTaxDescription from "./IndivTaxDescription";

const ECO_FRIENDLY_MODEL_LIST = [
  { modelCode: "8P21", model: "BMW i3 120Ah" },
  { modelCode: "71AW", model: "BMW I4 EDRIVE40 GRAN COUPE" },
  { modelCode: "31AW", model: "BMW I4 M50 GRAN COUPE" },
  { modelCode: "41DU", model: "BMW IX3 M SPORT" },
  { modelCode: "11CF", model: "BMW IX XDRIVE40" },
  { modelCode: "11DJ", model: "MINI COOPER SE HATCH" },
  { modelCode: "21CF", model: "BMW IX XDRIVE50" },
  { modelCode: "31CF", model: "BMW IX M60" },
  { modelCode: "51EJ", model: "BMW I7 XDRIVE60" },
  { modelCode: "61EF", model: "BMW iX1 xDrive30" },
  // 테스트 모델
  // { modelCode: "1S51", model: "BMW iX1 xDrive30" },

  // TODO: 등재 시 주석 해제
  // 환친차 등재 예정 모델 4개
  // { modelCode: "31FK", model: "i5 eDrive40" },
  // { modelCode: "41FK", model: "i5 M60 xDrive" },
  // { modelCode: "41EJ", model: "i7 eDrive50" },
  // { modelCode: "81EH", model: "i7 M70 xDrive" },
];

const NotAppliedComponent = ({
  vehicleData,
  searchApplied,
  inquiryHandler,
  refundType,
  setRefundType,
  refundExpected,
  setRefundExpected,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // 환친차 관련
  const modelCodeList = ECO_FRIENDLY_MODEL_LIST.map((model) => {
    return model.modelCode;
  });
  const vehicleModelCode = vehicleData?.vinNo?.slice(3, 7);
  const filteredVehicleModel = ECO_FRIENDLY_MODEL_LIST.filter(
    (model) => model.modelCode === vehicleModelCode
  )?.[0]?.model;

  useEffect(() => {
    if (modelCodeList.includes(vehicleModelCode)) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleModelCode]);
  //

  useEffect(() => {}, [vehicleData]);
  const refundTypeHandler = async (e) => {
    setRefundType(e.target.innerText);
    console.log("type : ", e.target.value);
    try {
      const result = await IVISAxios.get(`/indvreduction/expectation`, {
        params: {
          type: e.target.value,
          indv: vehicleData.IndvTax.replace(/,/g, ""),
          edu: vehicleData.EduTax.replace(/,/g, ""),
        },
      });

      let refundResultData = result.data;
      refundResultData.indvRefundExpected =
        refundResultData.indvRefundExpected.toLocaleString();
      refundResultData.eduRefundExpected =
        refundResultData.eduRefundExpected.toLocaleString();
      refundResultData.totalRefundExpected =
        refundResultData.totalRefundExpected.toLocaleString();
      setRefundExpected(refundResultData);
    } catch (e) {
      console.log(e);
      alert("에러");
    }
  };

  const reportTableMatch = {
    vinNo: "차대번호",
    cusReportNumber: "수입신고번호",
    aprvTime: "수리일자",
    modelYear: "연식",
    description: "Description",
  };

  const paidTaxMatch = {
    Gs: "관세",
    Vat: "부가세",
    IndvTax: "개별소비세",
    EduTax: "교육세",
    TotalTax: "총납부세액",
  };
  // //수리일자 출력 moment
  // console.log(moment(vehicleData.aprvTime).format("YYYY/MM/DD"));
  // //쉼표넣기
  // const Vat = 3853503;
  // console.log(Vat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

  const refundExpectedMatch = {
    indvRefundExpected: "개별소비세",
    eduRefundExpected: "교육세",
    totalRefundExpected: "총예상 환급액",
  };
  const authState = useRecoilValue(authoritiesAtom);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Grid container spacing={2}>
      {isOpen && (
        <EcoFriendlyVehicleDialog
          isOpen={isOpen}
          handleClose={handleClose}
          model={filteredVehicleModel}
        />
      )}

      <Grid item xs={12} md={6}>
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Box>
            <Card variant="outlined">
              <CardContent>
                <Typography
                  sx={{ fontSize: 30 }}
                  color="text.secondary"
                  gutterBottom
                >
                  신고정보 조회 <br />
                </Typography>
                <MUITableNoHeader
                  source={vehicleData}
                  match={reportTableMatch}
                />
              </CardContent>
            </Card>
          </Box>
          {/* 수리일자 : <div>{moment(vehicleData.aprvTime).format("YYYY/MM/DD")}</div> */}

          {searchApplied && !authState.includes("BMWDEALERSALES") ? (
            <Box>
              <Card variant="outlined">
                <CardContent>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: 30,
                    }}
                    color="text.secondary"
                    gutterBottom
                  >
                    납부세액 조회 <br />
                    {modelCodeList?.includes(vehicleModelCode) && (
                      <EcoFriendlyPopover text={"환친차 감면 적용 차량"} />
                    )}
                  </Typography>

                  {vehicleData ? (
                    <MUITableNoHeader
                      source={vehicleData}
                      match={paidTaxMatch}
                      onlyPrice
                    />
                  ) : (
                    <></>
                  )}
                </CardContent>
              </Card>
            </Box>
          ) : null}
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ flex: 1 }}>
          {searchApplied ? (
            <>
              <Box>
                <Box sx={{}}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 30 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                          }}
                        >
                          예상환급액 조회
                          <IndivTaxDescription />
                        </Box>
                        {/* {refundType ? ` / 선택 타입: ${refundType}` : null} */}
                      </Typography>
                      <Box>
                        <Button
                          variant="contained"
                          style={{
                            borderRadius: 10,
                            backgroundColor:
                              refundType === "장애인" ? "#338" : "#888",
                            padding: "10px",
                            fontSize: "15px",
                          }}
                          onClick={refundTypeHandler}
                          value={1}
                        >
                          장애인
                        </Button>

                        {/* <Button
                          variant="contained"
                          onClick={refundTypeHandler}
                          value={3}
                          style={{
                            borderRadius: 10,
                            backgroundColor:
                              refundType === "여객" ? "#356" : "#888",
                            padding: "10px",
                            fontSize: "15px",
                          }}
                        >
                          여객
                        </Button> */}

                        <Button
                          variant="contained"
                          onClick={refundTypeHandler}
                          value={4}
                          style={{
                            borderRadius: 10,
                            backgroundColor:
                              refundType === "렌터카" ? "#375" : "#888",
                            padding: "10px",
                            fontSize: "15px",
                          }}
                        >
                          렌터카
                        </Button>
                        <Button
                          variant="contained"
                          onClick={refundTypeHandler}
                          value={6}
                          //refundExpected.totalRefundExpected)
                          //WB10B0809N6G21613

                          style={{
                            borderRadius: 10,
                            backgroundColor:
                              refundType === "다자녀" ? "#800" : "#888",
                            padding: "10px",
                            fontSize: "15px",
                          }}
                        >
                          다자녀
                        </Button>
                      </Box>
                      <MUITableNoHeader
                        source={refundExpected}
                        match={refundExpectedMatch}
                        onlyPrice
                      />
                    </CardContent>
                  </Card>
                </Box>
              </Box>

              <Box>
                <Card variant="outlined">
                  <CardContent>
                    {refundType === "장애인" ? (
                      <>
                        <h3>※ 환급조건(1가지 조건만 해당하면 가능)</h3>
                        <ul>
                          <li>국가유공자 </li>
                          <li>장애등급 [1급, 2급, 3급 또는 정도가 심한] </li>
                          <li>고엽제후유의증환자 경도장애 이상</li>
                          <li>공동명의 환급신청 시 참고사항</li>
                          <br /> 1) 장애인과 세대를 함께하는 것이 확인되는
                          배우자, 직계존비속, 형제자매 <br /> 또는 직계비속의
                          배우자와 공동명의로 장애인 전용 승용차자동차를 구입한
                          경우
                          <br /> <br />
                          2) 혈연으로 맺어진 관계는 아니지만, 법률상 입양절차를
                          거쳐 자녀가 된 양부모,
                          <br /> 양자도 직계존비속 해당 가능 <br />
                          <br />
                          3) 혈연관계가 아닌 자녀의 배우자는 신청가능
                        </ul>

                        <h3> ※ 제출서류</h3>
                        <ul>
                          <li>환급신청서 / 면세반출신고서</li>
                          <li>수입신고필증</li>
                          <li>판매 세금계산서</li>
                          <li>자동차 등록증</li>
                        </ul>
                        <h3> ※ 증빙서류</h3>
                        <ul>
                          <li>
                            장애인 차량 : 장애인 등록증, 가족관계증명서(본인명의
                            아닐시)
                          </li>
                          <li>
                            고엽제 : 고엽제 등록증, 가족관계증명서(본인명의
                            아닐시)
                          </li>
                          <li>
                            국가유공자 : 등록증 또는 증빙서류,
                            가족관계증명서(본인명의 아닐시)
                          </li>
                          {/* <Boxcorner>
                            <BoxHeader>해당 차량의 경우</BoxHeader>
                            <Boxul>
                              <li>본인 명의 또는</li>
                              <li>
                                가족관계증명서상의 배우자, 직계존비속, 형제
                                <br /> 자매, 직계비속의 배우자와 공동명의
                              </li>
                            </Boxul>
                            <BoxSubHeader>
                              예시) 부모님, 외조부모님, 친조부모님, 아들, 딸,
                              손자녀
                            </BoxSubHeader>
                            <Boxul>
                              <li>
                                혈연으로 맺어진 관계는 아니지만, 법률상
                                입양절차를 거쳐 자녀가 된 양부모, 양자도
                                직계존비속 해당 가능
                              </li>
                              <li>혈연관계가 아닌 자녀의 배우자 → 신청가능</li>
                            </Boxul>
                          </Boxcorner> */}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                    {/*  2022-01-06 삭제요청 (전팀장님)
                    {refundType === "여객" ? (
                      <>
                        <h3> ※ 제출서류</h3>
                        <ul>
                          <li>환급신청서 / 면세반출신고서</li>
                          <li>수입신고필증</li>
                          <li>판매 세금계산서</li>
                          <li>자동차 등록증</li>
                        </ul>
                        <h3> ※ 증빙서류</h3>
                        <ul>
                          <li>
                            여객자동차 : 사업자등록증, 자동차 여객사업 등록증
                          </li>
                        </ul>
                      </>
                    ) : (
                      <></>
                    )} */}
                    {refundType === "렌터카" ? (
                      <>
                        <h3>※ 환급조건(1가지 조건만 해당하면 가능)</h3>
                        <ul>
                          <li>여객자동차운송사업에 사용하는 것 </li>
                          <li>
                            자동차대여사업에 사용되는 것(구입일로부터 3년 이내에
                            동일인 또는 <br /> 동일 법인에 대여한 기간의 합이
                            6개월을 초과하는 것은 제외){" "}
                          </li>
                        </ul>
                        <h3> ※ 제출서류</h3>
                        <ul>
                          <li>환급신청서 / 면세반출신고서</li>
                          <li>수입신고필증</li>
                          <li>판매 세금계산서</li>
                          <li>자동차 등록증</li>
                          <li>신분증 사본</li>
                        </ul>
                        <h3> ※ 증빙서류</h3>
                        <ul>
                          <li>
                            여객자동차 : 사업자등록증, 자동차 여객사업 등록증
                          </li>
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                    {refundType === "다자녀" ? (
                      <>
                        <h3>※ 환급조건</h3>
                        <ul>
                          <li>
                            다자녀가구 <br />
                            [18세 미만의 자녀(가족관계등록부를 기준으로 하고,
                            양자 및 배우자의 자녀를 포함하되 입양된 자녀는
                            친생부모의 자녀 수에는 포함하지 아니한다.)
                            <br /> 3명 이상을 양육하는 사람이 구입하는 것 (한도
                            300만원)]
                          </li>
                          <li>
                            * 환급신청 시 참고사항 <br />
                            <br /> 1) 부부가 공동명의로 진행하는 경우에만
                            환급신청 가능 <br />
                            ex) <br /> - 남편과 아내 공동명의 시 환급신청 가능{" "}
                            <br />- 남편과 남편의 아버지 공동명의 시 환급신청
                            불가능 <br />
                            <br /> 2) 재혼가정에서 배우자의 자녀를 포함하여 18세
                            미만의 자녀가 3명 이상인 경우 환급신청 가능
                          </li>
                        </ul>

                        <h3> ※ 제출서류</h3>
                        <ul>
                          <li>환급신청서 / 면세반출신고서</li>
                          <li>수입신고필증</li>
                          <li>판매 세금계산서</li>
                          <li>자동차 등록증</li>
                          <li>신분증 사본</li>
                        </ul>
                        <h3> ※ 증빙서류</h3>
                        <ul>
                          <li>다자녀가구 : 가족관계증명서, 주민등록등본</li>
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                    <Typography
                      sx={{ fontSize: 30 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      주의사항
                      {/* {refundType ? ` / 선택 타입: ${refundType}` : null} */}
                    </Typography>

                    <h4>※ 증빙서류 제출 시 유의사항</h4>
                    {refundType === "장애인" ? (
                      <>
                        <ul>
                          <li>제출하는 서류의 선명도 재확인</li>
                          <li>
                            장애인이 기업의 대표인 경우 법인으로 세금계산서 발행
                            가능 <br />
                            (다만, 대표자 성명에 장애인 차주 성명 기재)
                          </li>{" "}
                          <li>
                            국가유공자등록증 제출 시 등록일이 기재되어있는지
                            확인 필요
                            <br /> (등록일이 없는 경우 국가유공증서로 제출)
                          </li>
                          <li>
                            공동명의 차량 환급신청 시 참고사항 <br />
                            <br /> 1) 주민등록표, 외국인등록표 또는
                            국내거소신고원부에 의하여 장애인과 세대를 함께 하는
                            것이 확인되는 배우자, 직계존비속, 형제자매 또는
                            직계비속의 배우자와 공동명의로 장애인 전용
                            승용자동차를 구입하는 경우 등본을 제출하여야함.
                            <br /> <br /> 2) 등본에서 가족관계를 확인할 수 없는
                            경우 가족관계증명서를 추가 제출하여야 함.
                            <br /> <br /> 3) 등본 제출 시 거주일자(신고일)를
                            확인할 수 있도록 발급
                          </li>
                        </ul>
                      </>
                    ) : refundType === "다자녀" ? (
                      <>
                        <ul>
                          <li>제출하는 서류의 선명도 재확인</li>
                          <li>
                            자동차등록증 상 차주 본인 기준으로 증빙서류 준비
                          </li>{" "}
                          <li>
                            주민등록등본은 거주일자(신고일)가 명시되도록 발급
                          </li>
                          <li>
                            배우자의 자녀를 포함하여 18세 미만의 자녀가 3명인
                            경우 배우자 기준 가족관계증명서 필요 <br />
                          </li>{" "}
                          <li>
                            공동명의 차량 환급신청 시 참고사항 <br />
                            <br />
                            1) 공동명의인 경우 남편/아내 기준으로 각각 서류준비{" "}
                            <br /> <br />
                            2) 공동명의인 경우 세금계산서 발행 시 공동명의자
                            전부기재(기재할 수 없는 경우 비고란에 기재)
                          </li>
                        </ul>
                      </>
                    ) : refundType === "렌터카" ? (
                      <>
                        <ul>
                          <li>제출하는 서류의 선명도 재확인</li>{" "}
                          <li>사용본거지 지점의 사업자 등록증 제출 필요</li>
                        </ul>
                      </>
                    ) : null}

                    <h4>※ 환급신청 기한 </h4>
                    {refundType === "장애인" ? (
                      <>
                        차량등록일로부터 6개월이 속하는 달의 마지막일
                        <h4>※ 환급액</h4> 개소세 최대 500만원, 교육세 최대
                        150만원까지 환급
                      </>
                    ) : refundType === "다자녀" ? (
                      <>
                        2023년 수입된 차량 : 차량등록일부터 6개월이 속하는 달의
                        마지막일
                        <br />
                        2022년 수입된 차량 : 차량등록일이 속한 분기의 다음 달
                        25일까지
                        <h4>※ 환급액</h4> 개소세 최대 300만원, 교육세 최대
                        90만원까지 환급
                      </>
                    ) : refundType === "렌터카" ? (
                      <>
                        차량등록일부터 6개월이 속하는 달의 마지막일
                        <h4>※ 환급액</h4> 개별소비세, 교육세 전액 환급
                      </>
                    ) : null}
                  </CardContent>
                </Card>

                {searchApplied && !authState.includes("BMWDEALERSALES") ? (
                  <div>
                    <NotAppliedType
                      refundType={refundType}
                      vehicleData={vehicleData}
                      inquiryHandler={inquiryHandler}
                      buttonname="신청"
                      refundExpected={refundExpected}
                    />
                  </div>
                ) : null}
              </Box>
            </>
          ) : (
            <div></div>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default NotAppliedComponent;

const DataSpread = styled.div`
  font-size: 16px;
`;

const Boxcorner = styled.div`
  border: 2.5px solid navy;
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 400px;
`;

const BoxHeader = styled.div`
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
`;
const BoxSubHeader = styled.div`
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 5px;
`;

const Boxul = styled.ul`
  font-size: 15px;
`;
