import { Box } from "@mui/system";

const IndivTaxDescription = () => {
  return (
    <Box
      sx={{
        padding: "10px",
        color: "red",
        bgcolor: "pink",
        fontSize: "12px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 1,
      }}
    >
      * 해당 환급금은 개별소비세율 인하 전 세율(5%)로 산출된 내역입니다.
    </Box>
  );
};

export default IndivTaxDescription;
